<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div>
        <h6 class="title-h6 q-mt-md">{{$t('processes.linked_wallets')}}</h6>
        <div class="row q-col-gutter-md q-mb-md">
          <add-a-wallet v-if="currentFermetureGiProcess && currentFermetureGiProcess.wallets" @walletsUpdated="updateWallet" v-model="currentFermetureGiProcess.wallets" :wallets="currentFermetureGiProcess.wallets" class="col"/>
          <add-a-wallet v-else @walletsUpdated="updateWallet" class="col"/>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md">
          <q-card-section class="justify-center">
            <div class="row">
              <div class="col">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.commentaires.label')}}</q-item-label>
                <base-input-text class="q-px-sm q-pb-none row" placeholder="" label="" v-bind="formInputProps('commentaire')" :color="color"
                  v-model="formData.commentaire"  />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <h6 class="title-h6 process-color q-mt-none">{{$t('processes.dossier')}}</h6>
        <q-card flat bordered class="q-pa-sm q-mb-md">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_fermeture_gi')" :color="color"
                v-model="formData.date_fermeture_gi" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_envoi_demande_fermeture')" :color="color"
                v-model="formData.date_envoi_demande_fermeture" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_envoi_avis_au_service')" :color="color"
                v-model="formData.date_envoi_avis_au_service" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_maj_icx')" :color="color"
                v-model="formData.date_maj_icx" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.bailLocalMobilier')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_envoi_mail_de_fin_de_location')" :color="color"
                  v-model="formData.date_envoi_mail_de_fin_de_location" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_traitement_du_bail')" :color="color"
                  v-model="formData.date_traitement_du_bail" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_etat_des_lieux_de_sortie')" :color="color"
                  v-model="formData.date_etat_des_lieux_de_sortie" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_demande_resiliation_assurance_du_local')" :color="color"
                  v-model="formData.date_demande_resiliation_assurance_du_local" ref="label" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date class="" v-bind="formInputProps('cession_mobilier_acquis_pendant_la_gi')" :color="color"
                    v-model="formData.cession_mobilier_acquis_pendant_la_gi" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_resiliation_affranchigo')" :color="color"
                  v-model="formData.date_resiliation_affranchigo" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_demande_fermeture_du_CBC')" :color="color"
                  v-model="formData.date_demande_fermeture_du_CBC" ref="label" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-date class="" v-bind="formInputProps('date_effective_fermeture_CBC')" :color="color"
                    v-model="formData.date_effective_fermeture_CBC" ref="label" />
                </div>
              </div>
              <div class="row q-gutter-sm">
                <div class="col">
                  <base-input-date class="" v-bind="formInputProps('date_envoi_courrier_de_fin_de_mandat_de_gestion_courtage')" :color="color"
                    v-model="formData.date_envoi_courrier_de_fin_de_mandat_de_gestion_courtage" ref="label" />
                </div>
                <div class="col">
                  <base-input-date class="" v-bind="formInputProps('date_envoi_mail_fin_location_materiel_mobilier')" :color="color"
                    v-model="formData.date_envoi_mail_fin_location_materiel_mobilier" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.dateResiliationDesAbos')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reprise_gaz')" :color="color"
                v-model="formData.date_reprise_gaz" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reprise_eau')" :color="color"
                v-model="formData.date_reprise_eau" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_reprise_electricite')" :color="color"
                v-model="formData.date_reprise_electricite" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_reprise_nettoyage')" :color="color"
                v-model="formData.date_reprise_nettoyage" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.suiteCommercialeTelephone')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="justify-center">
            <div class="row">
              <div class="col-6">
                <base-input-date class="" v-bind="formInputProps('date_signature_suite_commerciale_telephonie')" :color="color"
                  v-model="formData.date_signature_suite_commerciale_telephonie" ref="label" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.personnel')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_transmission_registre_personnel')" :color="color"
                  v-model="formData.date_transmission_registre_personnel" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_mail_information_personnel_agence')" :color="color"
                  v-model="formData.date_mail_information_personnel_agence" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_envoi_courriers_aux_organismes_sociaux')" :color="color"
                  v-model="formData.date_envoi_courriers_aux_organismes_sociaux" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_mail_GGvie_pour_changement_de_titulaire_du_contrat_de_prev')" :color="color"
                  v-model="formData.date_mail_GGvie_pour_changement_de_titulaire_du_contrat_de_prev" ref="label" />
              </div>
              <div class="row q-gutter-sm">
                <base-input-date class="col" v-bind="formInputProps('date_paiement_passif_social')" :color="color"
                  v-model="formData.date_paiement_passif_social" ref="label" />
                <base-input-text class="col" v-bind="formInputProps('montant_passif_social')" :color="color"
                  v-model="formData.montant_passif_social" ref="label" />
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mb-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <div class="col-6">
                  <base-input-date class="" v-bind="formInputProps('date_envoi_courrier_transfert_contrat_travail')" :color="color"
                    v-model="formData.date_envoi_courrier_transfert_contrat_travail" ref="label" />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6 process-color">{{$t('processes.clotureAbos')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <q-card v-for="clotureAbonnement in relatedClotureAbos" :key="clotureAbonnement.id" flat bordered class="q-pa-sm q-mb-sm">
            <q-card-section class="justify-center">
              <div class="row q-gutter-x-sm">
                <base-input-text class="col" :label="$t('processes.fields.prestataire.label')" :color="color"
                    v-model="clotureAbonnement.nom_prestataire" />
                <base-input-date class="col" :label="$t('processes.fields.date_demande_cloture.label')" :color="color"
                    v-model="clotureAbonnement.date_demande_cloture" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-text class="" :label="$t('processes.fields.type.label')" :color="color"
                    v-model="clotureAbonnement.type" />
                </div>
              </div>
              <div class="row justify-end q-gutter-x-sm">
                <div class="col col-3 q-py-sm">
                  <q-btn :color="color" class="full-width" size="sm" outline @click="deleteClotureAbos(clotureAbonnement)" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
                </div>
                <div class="col col-3 q-py-sm">
                  <q-btn class="full-width" color="process" size="sm" @click="saveClotureAbos(clotureAbonnement)" :disabled="!isUpdate">{{$t('processes.save')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat bordered class="q-pa-sm q-mt-md">
            <q-card-section class="justify-center">
              <div class="row q-gutter-sm">
                <base-input-text class="col" :label="$t('processes.fields.prestataire.label')" :color="color"
                    value="" v-model="clotureAbonnement.nom_prestataire" />
                <base-input-date class="col" :label="$t('processes.fields.date_demande_cloture.label')" :color="color"
                    value="" v-model="clotureAbonnement.date_demande_cloture" />
              </div>
              <div class="row">
                <div class="col-6">
                  <base-input-text class="" :label="$t('processes.fields.type.label')" :color="color"
                    v-model="clotureAbonnement.type" />
                </div>
              </div>
              <div class="row q-mt-md justify-end">
                <div class="col col-3 q-py-sm q-px-md">
                  <q-btn class="full-width" color="process" size="sm" @click="saveClotureAbos()" :disabled="!isUpdate">{{$t('processes.enregistrer')}}</q-btn>
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      // related salariés
      relatedClotureAbos: [],
      clotureAbonnement: {
        nom_prestataire: '',
        date_demande_cloture: '',
        type: ''
      },
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentFermetureGiProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    relatedSuiviSignaletique: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'signaletique') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'signaletique')
        this.formData.related_objects.push(...newVal.map(suiviSignaletique => ({ model_type: 'signaletique', model_id: suiviSignaletique.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'signaletique')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'fermetureGi'
        if (!newVal || !this.currentFermetureGiProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentFermetureGiProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentFermetureGiProcess[id])
                  : this.currentFermetureGiProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentFermetureGiProcess.wallets ? this.currentFermetureGiProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          })) : []
          this.formData.related_objects.push(...walletsMapped)

          // Set cloture abonnement
          if (this.currentFermetureGiProcess.cloture_abonnements) {
            this.relatedClotureAbos = this.currentFermetureGiProcess.cloture_abonnements
            let clotureAbonnementsFormDataMapped = this.relatedClotureAbos.map(clotureAbonnement => ({
              model_type: 'clotureAbonnement',
              model_id: clotureAbonnement.id
            }))
            this.formData.related_objects.push(...clotureAbonnementsFormDataMapped)
          }
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.fermeture_gi'), to: { name: 'fermeture-gi-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'fermeture-gi-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.fermeture_gi'))

    this.$store.commit('pages/setTabs', [
      { name: 'fermeture-gi-form', label: 'Informations', to: { name: 'fermeture-gi-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'fermetureGi' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'fermetureGi')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'fermetureGi')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    deleteClotureAbos (clotureAbonnement) {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'clotureAbonnement', id: clotureAbonnement.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.relatedClotureAbos = this.relatedClotureAbos.filter(clotureAbo => clotureAbo.id !== clotureAbonnement.id)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },
    saveClotureAbos (clotureAbonnement) {
      let params = {
        ...clotureAbonnement || this.clotureAbonnement,
        model_type: 'clotureAbonnement',
        fermeture_gi_id: this.$route.params.id
      }

      this.$store.dispatch('processes/saveProcesses', params).then(data => {
        if (!clotureAbonnement) {
          this.relatedClotureAbos.push({ id: data.id, ...this.clotureAbonnement })
          this.clotureAbonnement = {}
          this.formData.related_objects.push({
            model_type: 'clotureAbonnement',
            model_id: data.id
          })
        }

        this.notifySuccess('processes.clotureAbos_added')
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'fermeture-gi-form', params: { id } })
    },
    copyQuestionnaire () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    copyMail () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    copyAvisService () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'fermetureGi', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
