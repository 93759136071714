import { render, staticRenderFns } from "./fiche.vue?vue&type=template&id=6a74135a&scoped=true&"
import script from "./fiche.vue?vue&type=script&lang=js&"
export * from "./fiche.vue?vue&type=script&lang=js&"
import style0 from "./fiche.vue?vue&type=style&index=0&id=6a74135a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a74135a",
  null
  
)

export default component.exports